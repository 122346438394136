import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var resp = loadStatus()
  }
}

function loadStatus() {
  var response = {}

  $(".js-loading-spinner").removeClass("hidden")
  const reloadTimeoutDuration = 10000

  $.ajax(window.location.href, {
    error: function(jqXHR, textStatus, errorThrown) {
      setTimeout(loadStatus, reloadTimeoutDuration)
    },
    success: function(data, textStatus, jqXHR) {
      $(".js-loading-spinner").addClass("hidden")
      response = data

      if (data.status == "notfound") {
        $("div[data-cluster-refresh-target=notfound]").removeClass("hidden")
        return
      }

      if (data.status == "launching") {
        if ($(".js-stage-launching").hasClass("hidden")) {
          startTimer(".js-stage-launching", data.remaining)
          $(".js-stage-launching").addClass("sm:flex").removeClass("hidden")
        }
        setTimeout(loadStatus, reloadTimeoutDuration)
        return
      }

      if (data.status == "authentication") {
        if ($(".js-stage-authentication").hasClass("hidden")) {
          startTimer(".js-stage-authentication", data.remaining)
          $(".js-stage-launching").removeClass("sm:flex").addClass("hidden")
          $(".js-stage-authentication").addClass("sm:flex").removeClass("hidden")
        }
        setTimeout(loadStatus, reloadTimeoutDuration)
        return
      }

      if (data.status == "installing") {
        if ($(".js-stage-installing").hasClass("hidden")) {
          startTimer(".js-stage-installing", data.remaining)
          $(".js-stage-launching").removeClass("sm:flex").addClass("hidden")
          $(".js-stage-authentication").removeClass("sm:flex").addClass("hidden")
          $(".js-stage-installing").addClass("sm:flex").removeClass("hidden")
        }
        setTimeout(loadStatus, reloadTimeoutDuration)
        return
      }

      if (data.status == "ready") {
        $(".js-stage-launching").removeClass("sm:flex").addClass("hidden")
        $(".js-stage-authentication").removeClass("sm:flex").addClass("hidden")
        $(".js-stage-installing").removeClass("sm:flex").addClass("hidden")
        $(".js-stage-ready").addClass("sm:flex").removeClass("hidden")

        $(".js-username").text(data.username)
        $(".js-password").text(data.password)
        $(".js-url").text("mongodb://" + data.username + ":" + data.password + "@"+ data.dns+":27017/ferretdb?authMechanism=PLAIN")
      }
    }
  })
}

function startTimer(selector, estimated) {
  var $progressBar = $(selector).find(".js-progress-bar")
  var $progressEstimated = $(selector).find(".js-progress-time")
  $progressBar.data("secondsRemaining", estimated)

  setInterval(function() {
    secondsRemaining = $progressBar.data("secondsRemaining")
    secondsRemaining -= 1
    $progressBar.data("secondsRemaining", secondsRemaining)
    percentage = (estimated - secondsRemaining) / estimated * 100

    minutes = Math.floor(secondsRemaining / 60)
    seconds = secondsRemaining - (minutes * 60)
    if (secondsRemaining <= 10) {
      $progressEstimated.text("Just finishing up...")
      percentage = 99
    } else {
      if (seconds < 10) {
        seconds = "0" + seconds.toString()
      }
      $progressEstimated.text(minutes + ":" + seconds + " left")
    }

    $progressBar.css("width", percentage.toString() + "%")
  }, 1000)
}
