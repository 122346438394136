import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "data", "copy", "complete" ]

  copy() {
    var text = this.dataTarget.innerText || this.dataTarget.textContent;
    navigator.clipboard.writeText(text)

    this.copyTarget.classList.add("hidden")
    this.completeTarget.classList.remove("hidden")

    var copyTarget = this.copyTarget
    var completeTarget = this.completeTarget

    setTimeout(function() {
      copyTarget.classList.remove("hidden")
      completeTarget.classList.add("hidden")
    }, 3000)
  }
}
